/** Generated sass for domain-specific styling
 * by common\modules\domains\helpers\DomainScssWriter
 */


//// Custom scss for domain #364 (woneninurbanvillage.nl) ////

// Bootstrap SORTED variables and domain specific custom colors: 

$dark: #212529;
$light: #f4f4f5;
$danger: #dc3545;
$warning: #ffc107;
$info: #0dcaf0;
$success: #198754;
$secondary: #fbb901;
$primary: #364294;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
);

//Add legacy styling from domain_css

//custom theme:
// @import url("https://themill.test/dist/styles/style.min.css"); 


@font-face {
  font-family: 'BookAntiqua';
       src: url('https://www.woneninurbanvillage.nl/fonts/BookAntiqua.woff2') format('woff2'), /* Super Modern Browsers */
       url('https://www.woneninurbanvillage.nl/fonts/BookAntiqua.woff') format('woff'), /* Pretty Modern Browsers */
       url('https://www.woneninurbanvillage.nl/fonts/BKANT.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('https://www.woneninurbanvillage.nl/fonts/BKANT.otf') format('opentype');
}


@font-face {
  font-family: 'Montserrat';
       src: url('https://www.woneninurbanvillage.nl/fonts/Montserrat-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('https://www.woneninurbanvillage.nl/fonts/Montserrat-Regular.otf') format('opentype');
}
// @import './themill';
$navbar-dark-color: white;

body, html {

	overflow-x: hidden;
	max-width: 100vw;
    color: $dark !important;
    font-family: "Montserrat", Arial, sans-serif;
}

.container {
	@include media-breakpoint-down(md) {
		padding-right: calc(var(--bs-gutter-x, .75rem) * 2) !important;
		padding-left: calc(var(--bs-gutter-x, .75rem) * 2) !important;
	}
	@include media-breakpoint-up(md) {
		padding-right: var(--bs-gutter-x, .75rem) !important;
		padding-left: var(--bs-gutter-x, .75rem) !important;
	}
	@media (min-width: 992px) {
		font-size: 100%;

	}
}

.custom-grid-container {
	@include media-breakpoint-down(lg) {
		margin-right: auto;
		margin-left: auto;
		padding-right: var(--bs-gutter-x, .75rem);
		padding-left: var(--bs-gutter-x, .75rem);
	}
    
}
	
::-moz-selection {
	color: $primary;
	background: rgba($secondary, .99);
}
::selection {
	color: $primary;
	background: rgba($secondary, .99);
}
* {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
	        box-sizing: border-box;
}
@-webkit-viewport {
	width: device-width;
}
@-moz-viewport {
	width: device-width;
}
@-ms-viewport {
	width: device-width;
}
@-o-viewport {
	width: device-width;
}
@viewport {
	width: device-width;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");




html {
	font-size: 85%;
	@media (max-width: 576px) {
		h1.display-1 {
			font-size: 4em !important;
		}
	}
	@media (min-width: 576px) {
		font-size: 90%;
		h1.display-1 {
			font-size: 5em !important ;
		}
	}
}
h1.display-1 {
	margin-bottom: .15em;
}
h1, h2, h3, h4, h5, h6 {
    letter-spacing: 0.5px;
	font-family: "BookAntiqua";
	text-transform: uppercase;
	color: $primary;
}
h2 {
	line-height: 1 !important;
	position: relative;
	margin-bottom: .8em !important;
	&.right {
		&:before {
			position: absolute;
			right: -25px;
			width: 4px;
			height: 90%;
			content: "";
			background-color: $white;
		}
	}
	&.top {
		&:before {
			position: absolute;
			top: -20px;
			width: 60px;
			height: 4px;
			content: "";
			background-color: $secondary;
		}
	}
	&.left {
		&:before {
			position: absolute;
			left: -25px;
			width: 4px;
			height: 90%;
			content: "";
			background-color: $primary;
		}
	}
}
h1.display-1 {
	position: relative;
	&.secondary:before {
		background-color: $secondary;
	}
	&.primary:before {
		background-color: $primary;
	}
	&.white:before {
		background-color: $white;
	}
	&:before {
		position: absolute;
		top: -20px;
		width: 60px;
		height: 4px;
		content: "";
	}
}
.page-lead {
	font-family: "BookAntiqua", Arial, sans-serif;
	font-size: 1.6em !important;
	font-weight: 600;
	line-height: 1.3;
	color: $secondary;
}
h1 {
	font-size: 3.2em !important;
}
h2 {
	font-size: 2.875em !important;
	@include media-breakpoint-down(lg) {
        font-size: 2em !important;
    }
}
.paragraph {
	margin-bottom: 1.4rem !important;
    font-family: "Montserrat", Arial, sans-serif;
	line-height: 1.9;

}
h3 {
	font-size: 2.75em !important;
	font-weight: bold !important;
	line-height: 1.4em;
	margin-bottom: 20px !important;
	letter-spacing: 0 !important;
}


.section-padding {
	@include media-breakpoint-down(md) {
		padding-top: 5em;
		padding-bottom: 5em;
	}
	@include media-breakpoint-up(md) {
		padding-top: 4em;
		padding-bottom: 4em;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 6em;
		padding-bottom: 6em;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 8em;
		padding-bottom: 8em;
	}
}


.menu-margin {
    margin-top: 108px;
}



.btn {
	font-family: "Montserrat" !important;
	font-size: 1.125em !important;
	font-weight: 600 !important;
	position: relative;
	z-index: 2;
	padding: .5em .9em !important;
	text-transform: uppercase;
	border-color: inherit;
	border-radius: 0 !important;
	&.btn-full  {
         &.btn-secondary:hover:before {
        
            background-color: $dark !important; 
        }
		&.text-white:after {
			color: $white;
		}
		&:after {
			color: $dark;
		}
		&:before {
			width: 100%;
		}
	}
	&.btn-secondary:hover:after {
		color: $dark;
	}
	&.btn-secondary.text-white:hover:after {
		color: $white;
	}
	&.no-arrow:after {
		display: none;
	}
	&:after {
		font: var(--fa-font-regular);
		font-weight: 200;
		padding-left: .35em;
		content: "\f178";
		color: $secondary;
	}
}
.btn-primary {
	border: transparent !important;
	background-color: transparent !important;
	&:after {
		transition: .3s ease-in-out all;
	}
	&:before {
		position: absolute;
		z-index: -1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		width: 3em;
		content: "";
		transition: cubic-bezier(.81,.12,.65,.86) .3s;
		background: $primary;
	}
	&:hover:before {
		width: 100%;
	}
}

.btn-secondary {
	border: transparent !important;
	background-color: transparent !important;
	&:after {
		transition: .3s ease-in-out all;
	}
	&:before {
		position: absolute;
		z-index: -1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		width: 3em;
		content: "";
		transition: cubic-bezier(.81,.12,.65,.86) .3s;
		background: $secondary;
	}
	&:hover:before {
		width: 100%;
	}
}
.pageblock_5656 {
.slider-section {
	@include media-breakpoint-down(md) {
		.slider-grid {
			max-width: 540px;
			margin-right: auto;
			margin-left: auto;
			padding-right: calc(var(--bs-gutter-x, .75rem) * 2) ;
			padding-left: calc(var(--bs-gutter-x, .75rem) * 2) ;
		}
	}
	@include media-breakpoint-up(md) {
		.slider-grid {
			max-width: 720px;
			padding-right: var(--bs-gutter-x, .75rem) ;
			padding-left: var(--bs-gutter-x, .75rem) ;
		}
	}
	@include media-breakpoint-down(lg) {
		background-color: $primary;
		background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/344/patroon_background1651582868_1651582871.png");
		background-position: center;
		background-size: cover;
		.slider-grid {
			margin-right: auto;
			margin-left: auto;
			padding-top: 3em;
			padding-bottom: 5em;


			.background {
				display: none;
			}
			.content {
				margin-bottom: 2em;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.slider-grid {
			display: grid;
			max-width: none;
			padding-right: 0;
			padding-left: 0;

			gap: 0 0;
			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 480px 480px minmax(var(--bs-gutter-x, .75rem), 1fr);
			grid-template-rows: 150px 1fr 150px;
			.content {
				padding-right: var(--bs-gutter-x, .75rem) ;
			}
		

			.background {
				background-color: $primary;
				background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/344/patroon_background1651582868_1651582871.png");
				background-position: center;
				background-size: cover;

				grid-area: 1 / 2 / 4 / 3;
			}
			.background2 {
				background-color: $primary;
				grid-area: 1 / 3 / 4 / 5;
			}
		}
	}
	@include media-breakpoint-up(xl) {
		.slider-grid {
			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 570px 570px minmax(var(--bs-gutter-x, .75rem), 1fr);
		}
	}
	@include media-breakpoint-up(xxl) {
		.slider-grid {
			grid-template-columns: 1fr 650px 650px 1fr;
		}
	}
	.slider-grid {

	    .photo {
          position: relative;
          background-color: transparent;
    
          grid-area: 2 / 1 / 3 / 3;
    
          img {
            display: block;
            width: 100%;
            height: 100%;
    
            object-fit: cover;
          }
        }

		.content {
			@include media-breakpoint-up(lg) {
				display: flex;
				flex-direction: column;
				padding-left: 5em;
				justify-content: center;

				align-items: start;
			}

			grid-area: 2 / 3 / 3 / 4;
			h2, p {
				text-align: left;
				color: $white;
			}
			h2:before  {
                position: absolute;
                left: -25px;
                width: 4px;
                height: 90%;
                content: "";
                background-color: $white;
            }

			a {
				display: inline-block;
				text-align: left;
			}
		}
	}
}






.planning-slider-container {
	position: relative;
	z-index: 1;
	overflow: hidden;
	width: 100%;
	height: 100%;
	
	margin-right: auto;
	margin-left: auto;
	padding: 0;
	list-style: none;
	.planning-wrapper {
		position: relative;
		z-index: 1;
		display: flex;
		box-sizing: content-box;
		width: 100%;
		height: 100%;
		transition-property: transform;


		.planning-item {
			position: relative;
			width: 100%;
			width: 100% !important;
			@include media-breakpoint-down(lg) {
				min-height: 500px !important;	
			}
			@include media-breakpoint-up(lg) {
				min-height: 600px !important;	
			}
			
			transition-property: transform;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			flex-shrink: 0;
			img {
				display: block;
				width: 100%;
				height: 100%;

				object-fit: cover;
			}
		}
	}

	.planning-scrollbar {
		position: absolute;
		z-index: 50;
		bottom: 3px;
		left: 1%;
		width: 98%;
		height: 5px;
	}

}


}
.pageblock_5657 {
.photo-section {
  @include media-breakpoint-down(md) {
    .photo-grid {
      max-width: 540px;
      margin-right: auto;
      margin-left: auto;
      padding-right: calc(var(--bs-gutter-x, .75rem) * 2) ;
      padding-left: calc(var(--bs-gutter-x, .75rem) * 2) ;
    }
  }
  @include media-breakpoint-up(md) {
    .photo-grid {
      max-width: 720px;
      padding-right: var(--bs-gutter-x, .75rem) ;
      padding-left: var(--bs-gutter-x, .75rem) ;
    }

  }
  @include media-breakpoint-down(lg) {
    background-color: $light;
    .photo-grid {
      margin-right: auto;
      margin-left: auto;
      padding-top: 3em;
      padding-bottom: 5em;


      .background {
        display: none;
      }
      .content {
        margin-bottom: 2em;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .photo-grid {
      display: grid;
      max-width: none;
      padding-right: 0;
      padding-left: 0;

      gap: 0 0;
      grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 480px 480px minmax(var(--bs-gutter-x, .75rem), 1fr);
      grid-template-rows: 150px 1fr 150px;
      .content {
        padding-right: var(--bs-gutter-x, .75rem) ;
      }
      .background {
        background-color: $light;
        grid-area: 1 / 4 / 4 / 5;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .photo-grid {
      grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 570px 570px minmax(var(--bs-gutter-x, .75rem), 1fr);
    }
  }
  @include media-breakpoint-up(xxl) {
    .photo-grid {
      grid-template-columns: 1fr 650px 650px 1fr;
    }
  }
  .photo-grid {
    .photo {
      position: relative;
      background-color: transparent;

      grid-area: 2 / 3 / 3 / 5;

      img {
        display: block;
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
    .content {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        padding-right: 5em;
        justify-content: center;

        align-items: start;
      }

      grid-area: 2 / 2 / 3 / 3;
      h2, .paragraph {
        color: $primary;
      }

     .page-lead {
        color: $secondary;
     }

      a {
        display: inline-block;
        text-align: left;
      }
    }
  }
}
}
.pageblock_5661 {
section {
  background-color:$light;

  h1:before  {
    position: absolute;
    left: -25px;
    width: 4px;
    height: 90%;
    content: "";
    background-color: $primary;
  }

  .flex-container {

    .flex-item {
      margin:15px;
      background-color:white;
      padding:30px;
      text-align:center;
      box-shadow: 0px 0px 20px 6px lightgrey;

      h6 {
        font-size: .8em;
        color: $secondary;
      }

      h3 {
        text-transform: none;
        font-size: 16px;
      }

      .border {
        display:block;
        width:150px;
        height:2px;
        background-color:#CCC;
        margin: auto;
        margin-bottom: 25px;
      }

      a {
        display:block;
        color: $secondary;
        padding-bottom:1em;
        text-decoration: underline;
        text-transform: initial;
        font-weight: normal !important;
        font-size: 1rem !important;
      }


    }
  }
}


}
.pageblock_5662 {
footer {
	background-color: rgba($secondary, .5);
    color:$primary;
	.logo {
        max-height:250px;
    }
	.paragraph {
		font-size: .9em;
		margin: 0;
		a {
			text-decoration: none;
			color: $primary;
			&:hover {
				color: $primary;
			}
		}
	}
	.fides-logo, .dekoning-logo {
	    height:30px;
	    
	}

    @include media-breakpoint-up(lg){
	    .logos-partners{
	        margin-top:70px;
	    }
	    
	    .right-block {
	        margin-top:70px;
	    }
	}

    @include media-breakpoint-up(xl){
        .fides-logo, .dekoning-logo {
        	height:40px;
    	}
        .logos-partners{
	        margin-top:70px;
	    }

    }

    @include media-breakpoint-up(xxl){
        .fides-logo, .dekoning-logo {
        	height:45px;
    	}

    }

	
	.logos-aangesloten img {
		width: 40px;
		height: auto;
        &:nth-of-type(1) {
			margin-right: .25em;
		}

	}
	.privacy-links a {
	    text-decoration: underline !important;
	    color: $primary;
	    font-weight: bold;
            
	}
}

}
.pageblock_5664 {
section {
  background-color: rgba(170, 139, 20, .2);

}
// h1 met streepje
h1 {
    position: relative;
    
    &:before{
        position: absolute;
    left: -25px;
    width: 4px;
    height: 90%;
    content: "";
    background-color: $primary;
    }
    
}
}
.pageblock_5665 {
section {
  background-color: rgba(170, 139, 20, .2);

}

.d-flex {
  flex-wrap: wrap;
  gap: 15px;
}

.flex-item {
    flex:1;
    
    @include media-breakpoint-up(md) {
        min-width:32%
    }
    
    .news-item {
        text-decoration:none;
    
        h3 {
            font-size: 2rem !important;
            text-transform:none;
        }
        
        h6 {
            font-size: .8em;
            color: $secondary;
            text-transform:none;
        }
        
        .news-img {
            background-position: center;
            height: 15em;
            background-color:white;
        }
    }
}

}
.pageblock_5666 {
.sub-footer {
	position: relative;
	overflow: hidden;
	background-color: $primary;
	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/349/patroon_bg1655114220_1655114223.png");
		opacity: .3;

		background-blend-mode: lighten;
		z-index: 0;
		
	}
	p {
		font-size: 1.1em;
		font-weight: 600;
	}
	h2, p {
		color: $white;
	}
}

}
.pageblock_5668 {
.container-md-big {
  @include media-breakpoint-down(sm) {
    max-width: 540px;
    padding-right: calc(var(--bs-gutter-x, 0.75rem)*2);
    padding-left: calc(var(--bs-gutter-x, 0.75rem)*2);
  }
  @include media-breakpoint-up(xxl) {
    max-width: 1375px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.navigation-main {
  position: absolute;
  z-index: 99;
  top: 0;
  transition: all .4s ease-in-out;
  width: 100%;
  background-color: $primary;
  &.navigation-open .header-content {
    transform: translateX(0%);
    opacity: 1;
  }
  &.fixed {
    position: fixed;

    background-color: $primary;
    .header {
      padding: .75em 0;
      .logo img {
        max-height: 4em;
      }
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    padding: 1.25em 0;
    transition: all .5s ease;
    justify-content: space-between;

    align-items: center;
    .logo {
      position: relative;
      z-index: 99;
      img {
        position: absolute;
        top: -33px;
        max-height: 5em;
        transition: all .5s ease;
      }
    }
  }
  .header-content {
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    transition: cubic-bezier(.81,.12,.65,.86) .6s;
    transform: translateX(100%);
    opacity: 0;
    background-color: $primary;
    @include media-breakpoint-down(md) {
      .header-content-menu {
        width: 60%;
      }
    }
    @include media-breakpoint-up(md) {
      .header-content-image {
        width: 35%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        -webkit-box-shadow: inset -3px 0 11px -2px rgba(0,0,0,.82);
        box-shadow: inset -3px 0 11px -2px rgba(0,0,0,.82);
      }
      .header-content-menu {
        width: 65%;
        padding-left: 10em;
        .triangle {
          position: absolute;
          right: 100%;
          transition: top .1s ease-in-out;
          border-width: 25px 35px 25px 0;
          border-style: solid;
          border-color: transparent $primary transparent transparent;
        }
      }
    }

    .header-content-menu {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0 auto;
      justify-content: center;
      .logo img {
        position: absolute;
        top: 2em;
        height: 100px;
      }

      ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style-type: none;
        li a {
          font-family: "Gotham";
          font-size: 2em;
          font-weight: 700;
          display: flex;
          padding: .3em .5em;
          text-decoration: none;
          color: $white;
          &:hover, &.active {
            color: $secondary;
          }
          
          @include media-breakpoint-down(md) {
            font-size: 1.125em !important;
            padding: 0.25em 0.5em;
          }
        }
      }
    }
  }
}


.burger-menu {
  font-size: 1.3em;
  font-weight: bold;
  z-index: 100;
  top: .7em;
  // right: 23px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  margin: 0 0 0 10%;
  padding: .8em .6em;
  cursor: pointer;
  -webkit-transition: top 1.5s ease-in-out;
  -moz-transition: top 1.5s ease-in-out;
  -o-transition: top 1.5s ease-in-out;
  transition: top 1.5s ease-in-out;
  transform: scale(.9);
  transform-origin: top right;
  text-transform: uppercase;
  color: $white;
  @include media-breakpoint-down(md) {
    right: 0;
  }
  @include media-breakpoint-up(lg) {
    right: 23px;
  }

  align-items: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
 
  
  .burger-buttons {
    margin-top: 0.3em;
    
    a {
        padding: 0.3em 0.9em !important;
        font-size: 1.3rem !important;
    }
  }
  
  .burger-text {
    font-family: "Gotham";
    // line-height: 2;
    overflow: hidden;
    height: 1.3em;

    .burger-text-open, .burger-text-closed {
      transition: .8s cubic-bezier(.68, -.6, .32, 1.6), .8s transform;
      transform: translateY(0);
    }
  }
  .burger-layers {
    display: flex;
    flex-direction: column;
    width: 30px;
    margin-left: .5em;
    padding: 0;
    cursor: pointer;

    align-items: flex-end;
    span {
      height: 3px;
      margin: 3px 0;
      transition: .4s cubic-bezier(.68, -.6, .32, 1.6), .2s background-color;
      background: $white;
      &:nth-of-type(1) {
        width: 100%;
      }
      &:nth-of-type(2) {
        width: 100%;
      }
      &:nth-of-type(3) {
        width: 100%;
      }
    }
  }
}
.navigation-main-open .burger-menu {
  .burger-buttons {
      display:none !important;
  }
  
  .burger-text {
    .burger-text-open, .burger-text-closed {
      transform: translateY(-100%);
    }
  }
  .burger-layers span {
    margin-left: -2px;
    background: $white;
    &:nth-of-type(1) {
      width: 50%;
      transform: rotatez(45deg) translate(-7px,12px);
      transform-origin: bottom;
    }
    &:nth-of-type(2) {
      width: 100%;
      transform: rotatez(-45deg);
      transform-origin: top;
    }
    &:nth-of-type(3) {
      width: 50%;
      transform: translate(-2px,-5px) rotatez(45deg);
      transform-origin: bottom;
    }
  }
}



}
.pageblock_5669 {
.project-header {

  position: relative;
  overflow: hidden;
  height: 80vh;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .cta-sticker {
    padding: 10px 30px;
    border-radius: 3px;
    margin-top: 30px;
    font-size: 20px;
    display: inline-block;
  }

  .slider-nav {
    .carousel-next,
    .carousel-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      i {
        background-color: transparent;
        color: rgba(white, .5);
        transition: all ease-in-out 0.2s;
        &:hover {
          color: rgba(white, 1);
        }
      }
    }
    .carousel-prev {
      left: 20px;
    }
    .carousel-next {
      right: 20px;
    }
  }
  .cta-link {
    position: absolute;
    bottom: 0;
    left: 15%;

    a {
      text-decoration: none;
    }

    .cta-link-downloads,
    .cta-link-images,
    .cta-link-prijslijst {
      padding: 0.5em 1em;
      transition: all 0.2s ease-in-out;
      border-top: 2px solid white;
      border-right: 2px solid white;
      border-left: 2px solid white;
      background-color: rgba(white, .8);

      &:hover {
        transform: translateY(10%);
        border-top: 2px solid $primary;
        border-right: 2px solid $primary;
        border-left: 2px solid $primary;
      }
      p::before {
        font-family: 'Font Awesome\ 5 Pro';
        display: inline-block;
        margin-right: 7.5px;
      }
    }
    .cta-link-images {
      p::before {
        content: ("\f302");
      }
    }
    .cta-link-downloads {
      p::before {
        content: ("\f019");
      }
    }
    .cta-link-prijslijst {
      p::before {
        content: ("\f0cb");
      }
    }
  }
}
}
.pageblock_5670 {

.page-menu {
    background-color: #f4f4f6
}

@media (max-width: 767.98px) {
    .page-menu {
        display: none
    }
}

.page-menu .menu {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    padding: 3em 0;
    list-style-type: none;
    align-items: center
}

.page-menu .menu li a {
    font-weight: 900;
    padding-right: 2em;
    text-decoration: none;
    color: $primary;
}

}
.pageblock_5671 {

.breadcrumbs-section .breadcrumbs {
    display: flex;
    flex-direction: row;
    padding: 3em 0;
    list-style-type: none;
    align-items: center
}

.breadcrumbs-section .breadcrumbs li {
    margin-right: 1em
}

.breadcrumbs-section .breadcrumbs li:first-child {
    font-weight: 900
}

.breadcrumbs-section .breadcrumbs a:hover {
    color: #223051
}

.breadcrumbs-section .breadcrumbs * {
     color: $primary
}

.breadcrumbs-section .breadcrumbs a {
    text-decoration: underline;
    color: $primary
}


}
.pageblock_5672 {
.lot-badge {
  width: 75px;
  height: 75px;
}

h1::after {
    margin-bottom: 10px;
}
}
.pageblock_5673 {



.table
{
    display: table;
    
    .table-row {
        display: table-row;
        
        & > div:nth-child(1){
            width: 40%;
        }
        & > div {
            display: table-cell;
            
            padding: .5rem .5rem;
        }
        
    }
    
    &.table-striped .table-row:nth-child(odd) {
        background-color: #f2f2f2;
    }   
}
}
.pageblock_5674 {
.media-item:nth-of-type(1n+5) {
   display:none;
}

.media-item .overlay {
    display: none;
    background-color: #000;
    opacity: 0.8;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    color: #fff;
}

.media-item:nth-child(4) .overlay {
    display: flex !important;
}




.media-container .media-wrapper {
	position: relative;
	display: inline-block;
	overflow: hidden;
	width: 100%;

	&:hover {
		a {
			.scaled-img {
				cursor: zoom-in;
				transform: scale(1.1);
			}
			&::after {
				opacity: 1;
			}
		}
	}
	a {
		position: relative;
		width: 100%;
		height: 200px; 
		display: block; 
		overflow: hidden;


		.scaled-img {
			position: relative;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat; 
			background-size: cover;
			transition: all .15s ease-in-out;
		}

		&.large-img {
			height: 430px;
		}
	}
}



}
.pageblock_5675 {
.download-content{
    border: 2px solid $light;
    padding:10px;
    background-color: $light;
    color:$dark;
    text-decoration:none;
}

.download-content:hover {
    border: 2px solid $secondary;
    color:$dark;
}


}
.pageblock_5692 {
.slider-section {
	@include media-breakpoint-down(md) {
		.slider-grid {
			max-width: 540px;
			margin-right: auto;
			margin-left: auto;
			padding-right: calc(var(--bs-gutter-x, .75rem) * 2) ;
			padding-left: calc(var(--bs-gutter-x, .75rem) * 2) ;
		}
	}
	@include media-breakpoint-up(md) {
		.slider-grid {
			max-width: 720px;
			padding-right: var(--bs-gutter-x, .75rem) ;
			padding-left: var(--bs-gutter-x, .75rem) ;
		}
	}
	@include media-breakpoint-down(lg) {
		background-color: $secondary;
		background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/349/patroon_bg1655114220_1655114223.png");
		background-position: center;
		background-size: cover;
		.slider-grid {
			margin-right: auto;
			margin-left: auto;
			padding-top: 3em;
			padding-bottom: 5em;


			.background {
				display: none;
			}
			.content {
				margin-bottom: 2em;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.slider-grid {
			display: grid;
			max-width: none;
			padding-right: 0;
			padding-left: 0;

			gap: 0 0;
			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 560px 400px minmax(var(--bs-gutter-x, .75rem), 1fr);
			grid-template-rows: 150px 1fr 150px;
			.content {
				padding-right: var(--bs-gutter-x, .75rem) ;
			}
			.background {
				background-color: $secondary;
				background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/349/patroon_bg1655114220_1655114223.png");
				background-position: center;
				background-size: cover;

				grid-area: 1 / 2 / 4 / 5;
			}
		}
	}
	@include media-breakpoint-up(xl) {
		.slider-grid {
			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 665px 475px minmax(var(--bs-gutter-x, .75rem), 1fr);
		}
	}
	@include media-breakpoint-up(xxl) {
		.slider-grid {
			grid-template-columns: 1fr 770px 550px 1fr;
		}
	}
	.slider-grid {
		.slider {
			position: relative;
			background-color: transparent;

			grid-area: 2 / 1 / 3 / 3;
		}
		.content {
			@include media-breakpoint-up(lg) {
				display: flex;
				flex-direction: column;
				padding-left: 5em;
				justify-content: center;

				align-items: end;
			}

			grid-area: 2 / 3 / 3 / 4;
			h2, p {
				text-align: right;
			}
			a {
				display: inline-block;
				text-align: right;
			}
		}
	}
}





.carousel-control-prev, .carousel-control-next {
	font-family: "FontAwesome";
	position: absolute;
	z-index: 999;
	bottom: -60px;
	width: 40px;
	height: 40px;
	transition: ease-in-out .2s all;
	opacity: .9;
	border: 2px solid $secondary;
	border-radius: 40px;
	top: inherit;

	align-items: center;
	text-rendering: auto;
	&:focus {
		border: none;
		outline: none;
	}
	i {
		font-size: 18px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #9dafbd;
	}
}

.carousel-control-next {
	right: 0;
	background-color: $primary;
	i {
		color: $secondary;
	}
	&:hover {
		border-color: $primary;
		background-color: transparent;
		i {
			color: $primary;
		}
	}
}
.carousel-control-prev {
	right: 50px;
	left: auto;
	background-color: transparent;
	i {
		color: $primary;
	}
	&:hover {
		background-color: $primary;
		i {
			color: $secondary;
		}
	}
}

h2.right:before, .right.h2:before {
    background-color: $primary;
}
}
.pageblock_5693 {
.image-grid {
	display: grid;

	gap: 0 0;
	grid-template-columns: 47.5% 5% 47.5%;
	grid-template-rows: 1fr 1fr;

	.image-1 {
		grid-area: 1 / 1 / 3 / 2;
	}
	.image-2 {
		grid-area: 1 / 3 / 3 / 3;
	}
}
}
.pageblock_5694 {
.usp-container {
	position: relative;
}
.usp-slider-container {
	position: relative;
	z-index: 1;
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 0;
	list-style: none;
	.usp-wrapper {
		position: relative;
		z-index: 1;
		display: flex;
		box-sizing: content-box;
		width: 100%;
		height: 100%;
		transition-property: transform;


		.usp-item {
			position: relative;
			width: 100%;
			padding: 2em;
			transition-property: transform;
			background-color: $primary;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			flex-shrink: 0;
			p {
				font-size: .9em;
				font-weight: 200;
				margin-bottom: 0;
				color:white;
			}
			img {
				display: block;
				width: 5em;
				margin-right: auto;
				margin-bottom: 1.5em;
				margin-left: auto;
				-webkit-filter: brightness(0) invert(1);
				filter: brightness(0) invert(1);
			}
		}
	}

	.usp-scrollbar {
		position: absolute;
		z-index: 50;
		bottom: 3px;
		left: 1%;
		width: 98%;
		height: 5px;
	}
	.swiper-pagination {
		position: absolute;
		z-index: 10;
		transition: .3s opacity;
		transform: translate3d(0,0,0);
		text-align: center;;
	}
	.swiper-pagination.swiper-pagination-hidden {
		opacity: 0;
	}
	.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
		bottom: 10px;
		left: 0;
		width: 100%;
	}

	.swiper-pagination-bullet {
		display: inline-block;
		width: 10px;
		height: 10px;
		margin-right: 10px;
		transition: .2s ease-in-out all;
		transform: scale(1.1);
		border-radius: 50%;
		background: #e4dbc1;
	}
	button.swiper-pagination-bullet {
		margin: 0;
		padding: 0;
		border: none;
		box-shadow: none;

		-webkit-appearance: none;
		        appearance: none;
	}
	.swiper-pagination-clickable .swiper-pagination-bullet {
		cursor: pointer;
	}
	.swiper-pagination-bullet:only-child {
		display: none!important;
	}
	.swiper-pagination-bullet-active {
		width: 13px;
		height: 13px;
		background: $secondary;
	}
}

.usp-button-next, .usp-button-prev {
	font-family: "FontAwesome";
	position: absolute;
	z-index: 999;
	bottom: -30px;
	transition: ease-in-out .2s all;
	opacity: 1;

	align-items: center;
	text-rendering: auto;
	&:focus {
		border: none;
		outline: none;
	}
	i {
		font-size: 15px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #9dafbd;
	}
}

.usp-button-next {
	right: 0;
	background-color: $primary;
	i {
		color: $primary;
	}
}
.usp-button-prev {
	right: 25px;
	background-color: transparent;
	i {
		color: $primary;
	}
}


h2.top:before, .top.h2:before {
    background-color: $primary
}
}
.pageblock_5695 {
.leveninwoerden-grid {

  @include media-breakpoint-up(sm) {
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;
  }
  @include media-breakpoint-down(md) {
    > * {
      padding-right: calc(var(--bs-gutter-x, 0.75rem)*2) !important;
      padding-left: calc(var(--bs-gutter-x, 0.75rem)*2) !important;
    }
  }
  @include media-breakpoint-only(md) {
    .slider {
      padding-left: var(--bs-gutter-x, .75rem);
    }
  }
  @include media-breakpoint-up(md) {
    display: grid;
    max-width: none;
    grid-template-columns: 1fr 720px 1fr;
    grid-template-rows: 75px auto auto 75px;
    gap: 0px 0px;
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 960px 1fr;
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 1140px minmax(var(--bs-gutter-x, .75rem), 1fr) ;
  }
  @include media-breakpoint-up(xxl) {
    grid-template-columns: 1fr 1320px 1fr;
    grid-template-rows: 150px auto auto 150px;
  }

  .text {
    grid-area: 2 / 2 /2 /3;
  }
  .slider {
    grid-area: 3 / 1 / 4 / 4;
  }

}


.slider {
  position: relative;
}


.leveninwoerden {

  @include media-breakpoint-down(md) {
    background-color: #2b3852;
    padding-top:5em;
    padding-bottom:5em;

  }

  h2 {
    color:$primary;
  }
  h2:before  {
    position: absolute;
    left: -25px;
    width: 4px;
    height: 90%;
    content: "";
    background-color: $primary;
  }

  p {
    color:$primary;
  }

}

.leveninwoerden-slider-container {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  list-style: none;
  .leveninwoerden-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    transition-property: transform;


    .leveninwoerden-item {
      position: relative;

      transition-property: transform;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      flex-shrink: 0;
      img {
        display: block;
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
  }

  .leveninwoerden-scrollbar {
    position: absolute;
    z-index: 50;
    bottom: 3px;
    left: 1%;
    width: 98%;
    height: 5px;
  }
  .swiper-pagination {
    position: absolute;
    z-index: 10;
    transition: .3s opacity;
    transform: translate3d(0,0,0);
    text-align: center;;
  }
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
  }
  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    transition: .2s ease-in-out all;
    transform: scale(1.1);
    border-radius: 50%;
    background: #e4dbc1;
  }
  button.swiper-pagination-bullet {
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;

    -webkit-appearance: none;
    appearance: none;
  }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
  }
  .swiper-pagination-bullet:only-child {
    display: none!important;
  }
  .swiper-pagination-bullet-active {
    width: 13px;
    height: 13px;
    background: $secondary;
  }
}

.leveninwoerden-button-next, .leveninwoerden-button-prev {
  font-family: "FontAwesome";
  position: absolute;
  z-index: 999;
  bottom: -30px;
  transition: ease-in-out .2s all;
  opacity: 1;

  align-items: center;
  text-rendering: auto;
  &:focus {
    border: none;
    outline: none;
  }
  i {
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #9dafbd;
  }
}

.leveninwoerden-button-next {
  left: 32px;
  background-color: $secondary;
  i {
    color: $secondary;
  }
}
.leveninwoerden-button-prev {
  left: 7px;
  background-color: transparent;
  i {
    color: $secondary;
  }
}

}
.pageblock_5707 {
section {
  background-color: #fdf8e5;
  
  h2.top:before, .top.h2:before {
    text-align: center;
    background-color: $primary;
    }

  h1:before {
    position: absolute;
    left: -25px;
    width: 4px;
    height: 90%;
    content: "";
    background-color: $primary;


    }

  .accordion-item {

    margin-bottom: 15px;
    border: none;

    .accordion-header {
      padding: 0;
      border: none;

      .accordion-button {
        background-color: transparent;
        font-weight: bold;
        padding: 2rem;
        border: none;
        box-shadow: none;

        &:not(.collapsed) {
          border: none;
          color: $primary;

          &::after {
            background-image: escape-svg($accordion-button-icon);

          }

        }

        &.collapsed:hover {
          background-color: $primary;
          color: $white;
        }


      }


    }

    .accordion-body {
      padding: 0 2rem 2rem;
    }
  }
}


}
.pageblock_5742 {
section {
  background-color: #f4f4f5;



// h1 met streepje
h1 {
    position: relative;
    
    &:before{
        position: absolute;
    left: -25px;
    width: 4px;
    height: 90%;
    content: "";

    }
    
}


  .flex-container {

    .flex-item {
      margin:15px;
      background-color:white;
      padding:30px;
      text-align:center;
      box-shadow: 0px 0px 20px 6px lightgrey;

      h6 {
        font-size: .8em;
        color: #a98b14;
      }

      h3 {
        text-transform: none;
      }

      .border {
        display:block;
        width:150px;
        height:2px;
        background-color:#CCC;
        margin: auto;
        margin-bottom: 25px;
      }

      a {
        display:block;
        color: #a98b14;
        padding-bottom:1em;
      }


    }
  }
}


}
.pageblock_5744 {
.price-list-table {

    .price-list-header {
        font-weight: bold;
    }

    .card {
        border: unset;

        .card-header {
            background-color: unset;
            border-top: 1px solid rgba(0,0,0,.125);
            border-bottom: unset;
            border-radius: unset;

            .status-available {
                color: #009900;
            }

            .status-option {
                color: #FFAA00;
            }

            .status-oral-agreement {
                color: #FF6600;
            }

            .status-sold {
                color: #CC0000;
            }

            &:hover {
                background-color: #F3F3F3;
            }
        }

        .card-content {
            .status-available {
                color: #fff;
                background-color: #009900;
            }

            .status-option {
                color: #fff;
                background-color: #FFAA00;
            }

            .status-oral-agreement {
                color: #fff;
                background-color: #FF6600;
            }

            .status-sold {
                color: #fff;
                background-color: #CC0000;
            }
        }
    }
}
}
.pageblock_6175 {
section {


  .flex-container {

    .flex-item {
      margin:15px;
      background-color:white;
      padding:30px;
      text-align:center;
      box-shadow: 0px 0px 20px 6px lightgrey;

      h6 {
        font-size: .8em;
        color: #a98b14;
      }

      h3 {
        text-transform: none;
      }

      .border {
        display:block;
        width:150px;
        height:2px;
        background-color:#CCC;
        margin: auto;
        margin-bottom: 25px;
      }

      a {
        display:block;
        color: #a98b14;
        padding-bottom:1em;
      }


    }
  }
}


}
.pageblock_6288 {
   height: 100%;
    background: linear-gradient(0deg, #f4f4f5 50%, #FFFFFF 50%);

}
.pageblock_6289 {
// h1 met streepje
h1 {
    position: relative;
    
    &:before{
        position: absolute;
    left: -25px;
    width: 4px;
    height: 90%;
    content: "";
    background-color: $primary;
    }
    
}

}
.pageblock_5748 {
.photo-section {

  @include media-breakpoint-down(md) {
    .photo-grid {
      max-width: 540px;
      margin-right: auto;
      margin-left: auto;
      padding-right: calc(var(--bs-gutter-x, .75rem) * 2) ;
      padding-left: calc(var(--bs-gutter-x, .75rem) * 2) ;
    }
  }
  @include media-breakpoint-up(md) {
    .photo-grid {
      max-width: 720px;
      padding-right: var(--bs-gutter-x, .75rem) ;
      padding-left: var(--bs-gutter-x, .75rem) ;
    }

  }
  @include media-breakpoint-down(lg) {
    background-color: #fceaae;
    background-image: url("#");
    background-position: center;
    background-size: cover;
    .photo-grid {
      margin-right: auto;
      margin-left: auto;
      padding-top: 3em;
      padding-bottom: 5em;


      .background {
        display: none;
      }
      .content {
        margin-bottom: 2em;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .photo-grid {
      display: grid;
      max-width: none;
      padding-right: 0;
      padding-left: 0;

      gap: 0 0;
	  grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 560px 400px minmax(var(--bs-gutter-x, .75rem), 1fr);
      grid-template-rows: 150px 1fr 150px;
      .content {
        padding-right: var(--bs-gutter-x, .75rem) ;
        h2,  p {
          text-align: right;
        }

      }
      .background {
        background-color: #fceaae;
        background-image: url("#");
        background-position: center;
        background-size: cover;

        grid-area: 1 / 2 / 4 / 5;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .photo-grid {
      grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 665px 475px minmax(var(--bs-gutter-x, .75rem), 1fr);
    }
  }
  @include media-breakpoint-up(xxl) {
    .photo-grid {
      grid-template-columns: 1fr 770px 550px 1fr;
    }
  }
  .photo-grid {
    .photo {
      position: relative;
      background-color: transparent;

      grid-area: 2 / 1 / 3 / 3;

      img {
        display: block;
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
    .content {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        padding-left: 5em;
        justify-content: center;

        align-items: end;
      }

      grid-area: 2 / 3 / 3 / 4;
      h2, p {
        // color: $white;
      }


      a {
        display: inline-block;
        text-align: right;
      }
    }
  }
}

h2.right:before, .right.h2:before {
    background-color: #364294;
}
}
.pageblock_5751 {
.photo-section {

  @include media-breakpoint-down(md) {
    .photo-grid {
      max-width: 540px;
      margin-right: auto;
      margin-left: auto;
      padding-right: calc(var(--bs-gutter-x, .75rem) * 2) ;
      padding-left: calc(var(--bs-gutter-x, .75rem) * 2) ;
    }
  }
  @include media-breakpoint-up(md) {
    .photo-grid {
      max-width: 720px;
      padding-right: var(--bs-gutter-x, .75rem) ;
      padding-left: var(--bs-gutter-x, .75rem) ;
    }

  }
  @include media-breakpoint-down(lg) {
    background-color: $secondary;
    background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/349/patroon_bg1655114220_1655114223.png");
    background-position: center;
    background-size: cover;
    .photo-grid {
      margin-right: auto;
      margin-left: auto;
      padding-top: 3em;
      padding-bottom: 5em;


      .background {
        display: none;
      }
      .content {
        margin-bottom: 2em;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .photo-grid {
      display: grid;
      max-width: none;
      padding-right: 0;
      padding-left: 0;

      gap: 0 0;
	  grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 560px 400px minmax(var(--bs-gutter-x, .75rem), 1fr);
      grid-template-rows: 150px 1fr 150px;
      .content {
        padding-right: var(--bs-gutter-x, .75rem) ;
        h2,  p {
          text-align: right;
        }

      }
      .background {
        background-color: $secondary;
        background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/349/patroon_bg1655114220_1655114223.png");
        background-position: center;
        background-size: cover;

        grid-area: 1 / 2 / 4 / 5;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .photo-grid {
      grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 665px 475px minmax(var(--bs-gutter-x, .75rem), 1fr);
    }
  }
  @include media-breakpoint-up(xxl) {
    .photo-grid {
      grid-template-columns: 1fr 770px 550px 1fr;
    }
  }
  .photo-grid {
    .photo {
      position: relative;
      background-color: transparent;

      grid-area: 2 / 1 / 3 / 3;

      img {
        display: block;
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
    .content {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        padding-left: 5em;
        justify-content: center;

        align-items: end;
      }

      grid-area: 2 / 3 / 3 / 4;
      h2, p {
        // color: $white;
      }


      a {
        display: inline-block;
        text-align: right;
      }
    }
  }
}

h2.right:before, .right.h2:before {
    background-color: $primary;
}
}
.pageblock_5752 {
.photo-section {
  @include media-breakpoint-down(md) {
    .photo-grid {
      max-width: 540px;
      margin-right: auto;
      margin-left: auto;
      padding-right: calc(var(--bs-gutter-x, .75rem) * 2) ;
      padding-left: calc(var(--bs-gutter-x, .75rem) * 2) ;
    }
  }
  @include media-breakpoint-up(md) {
    .photo-grid {
      max-width: 720px;
      padding-right: var(--bs-gutter-x, .75rem) ;
      padding-left: var(--bs-gutter-x, .75rem) ;
    }

  }
  @include media-breakpoint-down(lg) {
    background-color: $light;
    .photo-grid {
      margin-right: auto;
      margin-left: auto;
      padding-top: 3em;
      padding-bottom: 5em;


      .background {
        display: none;
      }
      .content {
        margin-bottom: 2em;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .photo-grid {
      display: grid;
      max-width: none;
      padding-right: 0;
      padding-left: 0;

      gap: 0 0;
      grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 480px 480px minmax(var(--bs-gutter-x, .75rem), 1fr);
      grid-template-rows: 150px 1fr 150px;
      .content {
        padding-right: var(--bs-gutter-x, .75rem) ;
      }
      .background {
        background-color: $light;
        grid-area: 1 / 4 / 4 / 5;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .photo-grid {
      grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 570px 570px minmax(var(--bs-gutter-x, .75rem), 1fr);
    }
  }
  @include media-breakpoint-up(xxl) {
    .photo-grid {
      grid-template-columns: 1fr 650px 650px 1fr;
    }
  }
  .photo-grid {
    .photo {
      position: relative;
      background-color: transparent;

      grid-area: 2 / 3 / 3 / 5;

      img {
        display: block;
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
    .content {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        padding-right: 5em;
        justify-content: center;

        align-items: start;
      }

      grid-area: 2 / 2 / 3 / 3;
      h2, .paragraph {
        color: $secondary;
      }

      .page-lead {
          color: $secondary;
      }

      a {
        display: inline-block;
        text-align: left;
      }
    }
  }
}

h2.top:before, .top.h2:before {
    background-color: $primary;
}
}
.pageblock_5756 {
section {
  background-color: rgba(170, 139, 20, .2);

}
   // h1 met streepje
    h1 {
        position: relative;
        
        &:before{
            position: absolute;
            left: -25px;
            width: 4px;
            height: 90%;
            content: "";
            background-color: $primary;
        }
    }
}
.pageblock_5757 {
.rent-calculator-container {
    .rent-calculator-left {
        background-color: #fceaae;
    }
    .rent-calculator-right {
          background-color: #fdf8e5;
    }

    .row {
        padding-bottom: 10px;
    }

    .rent-calculator-right {
        padding-left: 100px;
    }

    @media screen and (min-width: 768px) {
        height: 550px;
        background: linear-gradient(to right, #fceaae 50%, #fdf8e5 50%);
        position: relative;

        .rent-calculator-left:after {
            content: "";
            width: 0;
            height: 0;
            margin: -382px 0 0 -1px;
            border-style: solid;
            border-width: 275px 0 275px 49px;
            border-color: transparent transparent transparent #fceaae;
            position: absolute;
            left: 50%;
            top: 380px;
        }
    }
}
}
.pageblock_5761 {
// h1 met streepje
h1 {
    position: relative;
    
    &:before{
        position: absolute;
    left: -25px;
    width: 4px;
    height: 90%;
    content: "";
    background-color: $primary;
    }
    
}
}
.pageblock_5766 {
.news-img {
  background-position: center;
  height: 15em;
}
}
.pageblock_5769 {
.hero {
	position: relative;
	height: 100vh;
	
	background-color: $primary;
	@include media-breakpoint-down(md) {
	    min-height:1000px;
	
		.custom-container {
			max-width: 540px;
			padding-right: calc(var(--bs-gutter-x, .75rem)*2);
			padding-left: calc(var(--bs-gutter-x, .75rem)*2);
		}
	}
	@include media-breakpoint-up(md) {
		.custom-container {
		    .text {
		        padding-right: 10em;
		        padding-top: 50px;
		    }
			max-width: 720px;
			padding-right: var(--bs-gutter-x, .75rem);
			padding-left: var(--bs-gutter-x, .75rem);
		}
	}
	@include media-breakpoint-up(lg) {
		.custom-container {
			max-width: 960px;
		}
	}
	@include media-breakpoint-up(xl) {
		.custom-container {
			max-width: 1140px;
			padding-right: 0;
			padding-left: 0;

			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 585px 585px minmax(var(--bs-gutter-x, .75rem), 1fr);
			grid-template-rows: 120px 1fr 120px;
		}
	}
	@include media-breakpoint-down(xxl) {
		background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/349/patroon_bg1655114220_1655114223.png");
		background-position: center;
		background-size: cover;

		.background {
			display: none;
		}
	}
	@include media-breakpoint-up(xxl) {
		.custom-container {
			max-width: none;

			grid-template-columns: minmax(var(--bs-gutter-x, .75rem),1fr) 670px 705px minmax(var(--bs-gutter-x, .75rem),1fr);
		}
	}
	.custom-container {
		display: grid;
		height: 100%;
		margin-right: auto;
		margin-left: auto;
		gap: 0 0;
		@include media-breakpoint-down(md) {
		    padding-left: 80px;
		}
		.socials {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 2em 1em;
			background-color: $secondary;
			ul {
				display: flex;
				flex-direction: column;
				margin: 0;
				padding: 0;
				list-style-type: none;
				li {
					&:nth-of-type(1) {
						margin-bottom: .75em;
					}
					a {
						padding: .5em;
						color: $primary;
						&:hover {
							color: $white;
						}
						i {
							font-size: 1.4em;
						}
					}
				}
			}
		}
		.background {
			background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/349/patroon_bg1655114220_1655114223.png");
			background-position: center;
			background-size: cover;

			grid-area: 1 / 1 / 4 / 3;
		}
		.text {
			display: flex;
			flex-direction: column;
			justify-content: center;

			align-items: flex-start;
			grid-area: 2 / 2 / 3 / 3;
			p {
				font-size: 1.1em;
				margin-bottom: 3.4rem !important;
			}
		}
		.image {
			background-position: center;
			background-size: cover;

			grid-area: 2 / 3 / 4 / 5;
		}
		
		h1 {
		    font-size:3em !important;
		}
	}
}


.sticker {
    width: 175px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;

}

@include media-breakpoint-up(sm) {
    .sticker {
        bottom: 20px;
        right: 20px;
    }
}


@include media-breakpoint-up(md) {
    .sticker {
        bottom: 70px;
        right: 20px;
        width: 250px;

    }
}

@include media-breakpoint-up(lg) {
    .sticker {
        bottom: 120px;
        left: 250px;
    }
}

@include media-breakpoint-up(xl) {
    .sticker {
        bottom: 10px;
        left: 300px;
    }
}

@include media-breakpoint-up(xxl) {
    .sticker {
        bottom: 10px;
        left: 350px;
    }
}


}
.pageblock_5770 {
.hero-block {
	position: relative;
	z-index: 2;
	float: right;
	background-color: $secondary;
	@include media-breakpoint-down(md) {
		padding: calc(var(--bs-gutter-x, .75rem) * 2);
	}
	@include media-breakpoint-up(md) {
		width: 800px;
		margin-top: -120px;
		padding: 3em;
		position:absolute;
		right:0px;
	}
	b {
		font-size: 1em;
	}
	span {
	    color:$white;
	}
	a {
	    color:$primary;
	}
	p, .hero-block-link {
		font-size: .9em;
	}
	.hero-block-link {
		font-family: "Gotham";
		font-weight: 600;
		text-decoration: none;
		text-transform: uppercase;
		&:hover {
			color: $white;
			&:after {
				color: $white;
			}
		}
		&:after {
			font: var(--fa-font-regular);
			font-weight: 200;
			padding-left: .35em;
			content: "\f061";
			color: $primary;
		}
	}
	.col-3 {
		padding-top: 1.7em;
	}
	span {
		font-size: 1.375em;
		font-weight: 600;
		overflow: visible;
		width: 100%;
		height: 100%;
		margin-top: 1em;
		padding: 1em 1em 1.7em;
		background-image: url("https://stonepro.s3.eu-central-1.amazonaws.com/media/cache/large/domain/364/speeche_icon_urban.png");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	b {
		font-family: "Gotham";
		text-transform: uppercase;
	}
}

}
.pageblock_5771 {
.image-grid {
	display: grid;

	gap: 0 0;
	grid-template-columns: 47.5% 5% 47.5%;
	grid-template-rows: 1fr 1fr;

	.image-1 {
		grid-area: 1 / 1 / 3 / 2;
	}
	.image-2 {
		grid-area: 1 / 3 / 3 / 3;
    	width: 85%;
        align-self: end;
	}
}


.wonenin {
    position: relative;
    top: 3px;
    left: 75px;
    width: 200px;
    height: 30px;
    font-size: 2em;
    color:$secondary;
    letter-spacing: 4px;
   font-family: "Montserrat", Arial, sans-serif;
}

.page-lead {
    font-family: "Montserrat", Arial, sans-serif;
}
}
.pageblock_5772 {
.leveninwoerden-grid {

  @include media-breakpoint-up(sm) {
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;
  }
  @include media-breakpoint-down(md) {
    > * {
      padding-right: calc(var(--bs-gutter-x, 0.75rem)*2) !important;
      padding-left: calc(var(--bs-gutter-x, 0.75rem)*2) !important;
    }
  }
  @include media-breakpoint-only(md) {
    .slider {
      padding-left: var(--bs-gutter-x, .75rem);
    }
  }
  @include media-breakpoint-up(md) {
    display: grid;
    max-width: none;
    grid-template-columns: 1fr 720px 1fr;
    grid-template-rows: 75px auto auto 75px;
    gap: 0px 0px;
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 960px 1fr;
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 1140px minmax(var(--bs-gutter-x, .75rem), 1fr) ;
  }
  @include media-breakpoint-up(xxl) {
    grid-template-columns: 1fr 1320px 1fr;
    grid-template-rows: 150px auto auto 150px;
  }

  .background {
    background-color: #2b3852;
    grid-area: 1 / 1 / 5 / 3;
  }

  .text {
      grid-area: 2 / 2 /2 /3;
      
  }
  .slider {
    grid-area: 3 / 1 / 4 / 4;
  }
  
  .yellow-background {
    grid-area: 4 / 3 / 4 / 3;
    background-color: $light;
  }

}


.slider {
  position: relative;
}


.leveninwoerden {

  @include media-breakpoint-down(md) {
    background-color: #2b3852;
    padding-top:5em;
    padding-bottom:5em;
    
  }
   
    h2 {
        color:white;
    }
    h2:before  {
        position: absolute;
        left: -25px;
        width: 4px;
        height: 90%;
        content: "";
        background-color: white;
    }
    
    p {
        color:white;
    }

}



// bs5 multi-slider css code
@include media-breakpoint-down(md) {
    .carousel-inner .carousel-item > div {
        display: none;
    }
    .carousel-inner .carousel-item > div:first-child {
        display: block;
    }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
    gap: 20px;
}

@include media-breakpoint-up(md) {
    
    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(calc(33% + 25px));
    }
    
    .carousel-inner .carousel-item-start.active, 
    .carousel-inner .carousel-item-prev {
      transform: translateX(calc(-33% - 25px));
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start { 
  transform: translateX(0);
}




// bs5 slider code to customize next and previous buttons

.carousel-control-prev, .carousel-control-next {
  font-family: "FontAwesome";
  position: absolute;
  z-index: 999;
  bottom: -60px;
  width: 40px;
  height: 40px;
  transition: ease-in-out .2s all;
  opacity: .9;
  border: 2px solid $secondary;
  border-radius: 40px;
  top: inherit;

  align-items: center;
  text-rendering: auto;
  &:focus {
    border: none;
    outline: none;
  }
  i {
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #9dafbd;
  }
}

.carousel-control-next {
  left: 57px;
  background-color: $secondary;
  i {
    color: $primary;
  }
  &:hover {
    border-color: $secondary;
    background-color: transparent;
    i {
      color: $secondary;
    }
  }
}
.carousel-control-prev {
  left: 7px;
  background-color: transparent;
  i {
    color: $secondary;
  }
  &:hover {
    background-color: $secondary;
    i {
      color: $primary;
    }
  }
}

}
.pageblock_5773 {
.custom-grid-container {
	background-color: $light;
	@include media-breakpoint-up(sm) {
		.custom-grid {
			max-width: 540px;
			margin-right: auto;
			margin-left: auto;
		}
	}
	@include media-breakpoint-down(md) {
		.custom-grid {
			 > * {
				padding-right: var(--bs-gutter-x, .75rem);
				padding-left: var(--bs-gutter-x, .75rem);
			}
		}
	}
	@include media-breakpoint-up(md) {
		.custom-grid {
			max-width: 720px;
		}
	}
	@include media-breakpoint-up(lg) {
		.custom-grid {
			display: grid;
			max-width: none;
			margin-right: auto;
			margin-left: auto;

			gap: 0 0;
			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 400px 160px 400px minmax(var(--bs-gutter-x, .75rem), 1fr);
			grid-template-rows: auto 100px auto auto;
			 > *:not(.image-1):not(.image-2) {
				padding-right: var(--bs-gutter-x, .75rem);
				padding-left: var(--bs-gutter-x, .75rem);
			}
			.content-2, .content-3 {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			.content-1 {
				grid-area: 1 / 2 / 2 / 3;
				h2 {
					width: 68%;
				}
			}
			.image-1 {
				grid-area: 1 / 4 / 3 / 6;
			}
			.image-2 {
				grid-area: 2 / 1 / 4 / 3;
				.grid-image {
					padding-right: var(--bs-gutter-x, .75rem);
					padding-left: 6vw;
				}
			}
			.content-2 {
				grid-area: 3 / 4 / 4 / 5;
			}
			.content-3 {
				text-align: right;

				grid-area: 4 / 2 / 5 / 3;
			}
			.image-3 {
				grid-area: 4 / 4 / 5 / 5;
			}
			.timeline {
				display: flex;
				justify-content: center;

				align-items: center;
				grid-area: 1 / 3 / 5 / 4;
				.line {
					position: relative;
					width: 5px;
					height: 100%;
					background-color: $primary;
					.stip-1, .stip-2, .stip-3 {
						position: absolute;
						top: 6px;
						left: -3px;
						width: 11px;
						height: 11px;
						transform: rotate(45deg);
						outline: 2px solid #eee8d0;
						background-color: $primary;
					}
					.stip-1 {
						top: 15%;
					}
					.stip-2 {
						top: 50%;
					}
					.stip-3 {
						top: 75%;
					}
					&:before {
						top: 0;
					}
					&:after {
						bottom: 0;
					}
					&:before, &:after {
						position: absolute;
						left: -24px;
						width: 50px;
						height: 4px;
						content: "";
						background-color: $primary;
					}
				}
			}
		}
	}
	@include media-breakpoint-up(xl) {
		.custom-grid {
			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 475px 190px 475px minmax(var(--bs-gutter-x, .75rem), 1fr) ;
		}
	}
	@include media-breakpoint-up(xxl) {
		.custom-grid {
			grid-template-columns: 1fr 550px 220px 550px 1fr;
			grid-template-rows: auto 100px auto auto;
		}
	}
	.custom-grid .image-2 {
		.grid-image {
			display: grid;

			gap: 0 0;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 80%;
			.image {
                grid-area: 1 / 2 /2 / 3;
            }
		}
	}
}


h2.left:before, .left.h2:before {
    background-color: $primary;
}
}
.pageblock_5774 {
.slider-section {
  @include media-breakpoint-down(md) {
    .slider-grid {
      max-width: 540px;
      margin-right: auto;
      margin-left: auto;
      padding-right: calc(var(--bs-gutter-x, .75rem) * 2) ;
      padding-left: calc(var(--bs-gutter-x, .75rem) * 2) ;
    }
  }
  @include media-breakpoint-up(md) {
    .slider-grid {
      max-width: 720px;
      padding-right: var(--bs-gutter-x, .75rem) ;
      padding-left: var(--bs-gutter-x, .75rem) ;
    }
  }
  @include media-breakpoint-down(lg) {
    background-color: $primary;
    background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/344/patroon_background1651582868_1651582871.png");
    background-position: center;
    background-size: cover;
    .slider-grid {
      margin-right: auto;
      margin-left: auto;
      padding-top: 3em;
      padding-bottom: 5em;


      .background {
        display: none;
      }
      .content {
        margin-bottom: 2em;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .slider-grid {
      display: grid;
      max-width: none;
      padding-right: 0;
      padding-left: 0;

      gap: 0 0;
      grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 480px 480px minmax(var(--bs-gutter-x, .75rem), 1fr);
      grid-template-rows: 150px 1fr 150px;
      .content {
        padding-right: var(--bs-gutter-x, .75rem) ;
      }
      .background {
        background-color: $primary;
        background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/349/patroon_bg1655114220_1655114223.png");
        background-position: center;
        background-size: cover;

        grid-area: 1 / 1 / 4 / 5;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .slider-grid {
      grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 570px 570px minmax(var(--bs-gutter-x, .75rem), 1fr);
    }
  }
  @include media-breakpoint-up(xxl) {
    .slider-grid {
      grid-template-columns: 1fr 670px 650px 1fr;
    }
  }
  .slider-grid {
    .slider {
      position: relative;
      background-color: transparent;

      grid-area: 2 / 3 / 3 / 5;
    }
    .content {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        padding-right: 8em;
        justify-content: center;

        align-items: start;
      }

      grid-area: 2 / 2 / 3 / 3;
      h2, p {
        text-align: left;
        color: $white;
      }
      h2:before  {
        position: absolute;
        left: -25px;
        width: 4px;
        height: 90%;
        content: "";
        background-color: white;
      }


      a {
        display: inline-block;
        text-align: left;
      }
    }
  }
}




.carousel-control-prev, .carousel-control-next {
  font-family: "FontAwesome";
  position: absolute;
  z-index: 999;
  bottom: -60px;
  width: 40px;
  height: 40px;
  transition: ease-in-out .2s all;
  opacity: .9;
  border: 2px solid $secondary;
  border-radius: 40px;
  top: inherit;

  align-items: center;
  text-rendering: auto;
  &:focus {
    border: none;
    outline: none;
  }
  i {
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #9dafbd;
  }
}

.carousel-control-next {
  left: 50px;
  background-color: $secondary;
  i {
    color: $primary;
  }
  &:hover {
    border-color: $secondary;
    background-color: transparent;
    i {
      color: $secondary;
    }
  }
}
.carousel-control-prev {
  left: 0;
  background-color: transparent;
  i {
    color: $secondary;
  }
  &:hover {
    background-color: $secondary;
    i {
      color: $primary;
    }
  }
}

}
.pageblock_5775 {
.slider-section {
	@include media-breakpoint-down(md) {
		.slider-grid {
			max-width: 540px;
			margin-right: auto;
			margin-left: auto;
			padding-right: calc(var(--bs-gutter-x, .75rem) * 2) ;
			padding-left: calc(var(--bs-gutter-x, .75rem) * 2) ;
		}
	}
	@include media-breakpoint-up(md) {
		.slider-grid {
			max-width: 720px;
			padding-right: var(--bs-gutter-x, .75rem) ;
			padding-left: var(--bs-gutter-x, .75rem) ;
		}
	}
	@include media-breakpoint-down(lg) {
		.slider-grid {
			margin-right: auto;
			margin-left: auto;
			padding-top: 3em;
			padding-bottom: 5em;

			.content {
				margin-bottom: 2em;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.slider-grid {
			display: grid;
			max-width: none;
			padding-right: 0;
			padding-left: 0;

			gap: 0 0;
			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 560px 400px minmax(var(--bs-gutter-x, .75rem), 1fr);
			grid-template-rows: 150px 1fr 50px;
			.content {
				padding-right: var(--bs-gutter-x, .75rem) ;
			}
		}
	}
	@include media-breakpoint-up(xl) {
	    .slider {
	        padding-right: 5em;
	    }
	
		.slider-grid {
			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 665px 475px minmax(var(--bs-gutter-x, .75rem), 1fr);
		}
	}
	@include media-breakpoint-up(xxl) {
		.slider-grid {
			grid-template-columns: 1fr 670px 650px 1fr;
		}
	}
	.slider-grid {
		.slider {
			position: relative;
			background-color: transparent;

			grid-area: 2 / 2 / 3 / 3;
		}
		.photo {
          position: relative;
          background-color: transparent;
    
          grid-area: 2 / 2 / 3 / 3;
    
          img {
            display: block;
            width: 100%;
            height: 100%;
    
            object-fit: cover;
          }
        }

		
		.content {
			@include media-breakpoint-up(lg) {
				display: flex;
				flex-direction: column;
				padding-left: 5em;
				justify-content: center;

				align-items: start;
			}

			grid-area: 2 / 3 / 3 / 4;
			h2, p {
				text-align: left;
				color: $primary;
			}
			h2:before  {
                position: absolute;
                left: -25px;
                width: 4px;
                height: 90%;
                content: "";
                background-color: $primary;
            }

			a {
				display: inline-block;
				text-align: left;
			}
		}
	}
}





}
.pageblock_5776 {
.usp-container {
	position: relative;
}
.usp-slider-container {
	position: relative;
	z-index: 1;
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 0;
	list-style: none;
	.usp-wrapper {
		position: relative;
		z-index: 1;
		display: flex;
		box-sizing: content-box;
		width: 100%;
		height: 100%;
		transition-property: transform;


		.usp-item {
			position: relative;
			width: 100%;
			padding: 2em;
			transition-property: transform;
			background-color: $primary;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			flex-shrink: 0;
			p {
				font-size: .9em;
				font-weight: 200;
				margin-bottom: 0;
				color:white;
			}
			img {
				display: block;
				width: 5em;
				margin-right: auto;
				margin-bottom: 1.5em;
				margin-left: auto;
				-webkit-filter: brightness(0) invert(1);
				filter: brightness(0) invert(1);
			}
		}
	}

	.usp-scrollbar {
		position: absolute;
		z-index: 50;
		bottom: 3px;
		left: 1%;
		width: 98%;
		height: 5px;
	}
	.swiper-pagination {
		position: absolute;
		z-index: 10;
		transition: .3s opacity;
		transform: translate3d(0,0,0);
		text-align: center;;
	}
	.swiper-pagination.swiper-pagination-hidden {
		opacity: 0;
	}
	.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
		bottom: 10px;
		left: 0;
		width: 100%;
	}

	.swiper-pagination-bullet {
		display: inline-block;
		width: 10px;
		height: 10px;
		margin-right: 10px;
		transition: .2s ease-in-out all;
		transform: scale(1.1);
		border-radius: 50%;
		background: #e4dbc1;
	}
	button.swiper-pagination-bullet {
		margin: 0;
		padding: 0;
		border: none;
		box-shadow: none;

		-webkit-appearance: none;
		        appearance: none;
	}
	.swiper-pagination-clickable .swiper-pagination-bullet {
		cursor: pointer;
	}
	.swiper-pagination-bullet:only-child {
		display: none!important;
	}
	.swiper-pagination-bullet-active {
		width: 13px;
		height: 13px;
		background: $secondary;
	}
}

.usp-button-next, .usp-button-prev {
	font-family: "FontAwesome";
	position: absolute;
	z-index: 999;
	bottom: -30px;
	transition: ease-in-out .2s all;
	opacity: 1;

	align-items: center;
	text-rendering: auto;
	&:focus {
		border: none;
		outline: none;
	}
	i {
		font-size: 15px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #9dafbd;
	}
}

.usp-button-next {
	right: 0;
	background-color: $secondary;
	i {
		color: $secondary;
	}
}
.usp-button-prev {
	right: 25px;
	background-color: transparent;
	i {
		color: $secondary;
	}
}


h2.top:before, .top.h2:before {
    background-color: $primary
}
}
.pageblock_5777 {
.slider-section {
	@include media-breakpoint-down(md) {
		.slider-grid {
			max-width: 540px;
			margin-right: auto;
			margin-left: auto;
			padding-right: calc(var(--bs-gutter-x, .75rem) * 2) ;
			padding-left: calc(var(--bs-gutter-x, .75rem) * 2) ;
		}
	}
	@include media-breakpoint-up(md) {
		.slider-grid {
			max-width: 720px;
			padding-right: var(--bs-gutter-x, .75rem) ;
			padding-left: var(--bs-gutter-x, .75rem) ;
		}
	}
	@include media-breakpoint-down(lg) {
		background-color: $secondary;
		background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/344/patroon_background1651582868_1651582871.png");
		background-position: center;
		background-size: cover;
		.slider-grid {
			margin-right: auto;
			margin-left: auto;
			padding-top: 3em;
			padding-bottom: 5em;


			.background {
				display: none;
			}
			.content {
				margin-bottom: 2em;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.slider-grid {
			display: grid;
			max-width: none;
			padding-right: 0;
			padding-left: 0;

			gap: 0 0;
			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 560px 400px minmax(var(--bs-gutter-x, .75rem), 1fr);
			grid-template-rows: 150px 1fr 150px;
			.content {
				padding-right: var(--bs-gutter-x, .75rem) ;
			}
			.background {
				background-color: $secondary;
				background-image: url("https://s3.eu-central-1.amazonaws.com/stonepro/media/original/domain/349/patroon_bg1655114220_1655114223.png");
				background-position: center;
				background-size: cover;

				grid-area: 1 / 2 / 4 / 5;
			}
		}
	}
	@include media-breakpoint-up(xl) {
		.slider-grid {
			grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 665px 475px minmax(var(--bs-gutter-x, .75rem), 1fr);
		}
	}
	@include media-breakpoint-up(xxl) {
		.slider-grid {
			grid-template-columns: 1fr 770px 550px 1fr;
		}
	}
	.slider-grid {
		.slider {
			position: relative;
			background-color: transparent;

			grid-area: 2 / 1 / 3 / 3;
		}
		.content {
			@include media-breakpoint-up(lg) {
				display: flex;
				flex-direction: column;
				padding-left: 5em;
				justify-content: center;

				align-items: end;
			}

			grid-area: 2 / 3 / 3 / 4;
			h2, p {
				text-align: right;
				color: $primary;
			}
			a {
				display: inline-block;
				text-align: right;
			}
		}
	}
}





.carousel-control-prev, .carousel-control-next {
	font-family: "FontAwesome";
	position: absolute;
	z-index: 999;
	bottom: -60px;
	width: 40px;
	height: 40px;
	transition: ease-in-out .2s all;
	opacity: .9;
	border: 2px solid $secondary;
	border-radius: 40px;
	top: inherit;

	align-items: center;
	text-rendering: auto;
	&:focus {
		border: none;
		outline: none;
	}
	i {
		font-size: 18px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #9dafbd;
	}
}

.carousel-control-next {
	right: 0;
	background-color: $primary;
	i {
		color: $secondary;
	}
	&:hover {
		border-color: $primary;
		background-color: transparent;
		i {
			color: $primary;
		}
	}
}
.carousel-control-prev {
	right: 50px;
	left: auto;
	background-color: transparent;
	i {
		color: $primary;
	}
	&:hover {
		background-color: $primary;
		i {
			color: $secondary;
		}
	}
}


h2.right:before, .right.h2:before {
    background-color: $primary;
}
}
.pageblock_5778 {
.hotspot-grid {
    @include media-breakpoint-up(sm) {
        max-width: 540px;
        margin-right: auto;
        margin-left: auto;
    }
    @include media-breakpoint-down(md) {
        > * {
            padding-right: calc(var(--bs-gutter-x, 0.75rem)*2) !important;
            padding-left: calc(var(--bs-gutter-x, 0.75rem)*2) !important;
        }
    }
    @include media-breakpoint-only(md) {
        .slider {
            padding-left: var(--bs-gutter-x, .75rem);
        }
    }
    @include media-breakpoint-up(md) {
        display: grid; 
        max-width: none;
        grid-template-columns: 1fr 720px 1fr; 
        grid-template-rows: 1fr; 
        gap: 0px 0px; 
    }
    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 960px 1fr; 
    }
    @include media-breakpoint-up(xl) {
        grid-template-columns: minmax(var(--bs-gutter-x, .75rem), 1fr) 1140px minmax(var(--bs-gutter-x, .75rem), 1fr) ;
    }
    @include media-breakpoint-up(xxl) {
        grid-template-columns: 1fr 1320px 1fr;
        grid-template-rows: auto 100px auto auto;
    }
    .slider { 
        grid-area: 1 / 2 / 2 / 4; 
    }
}


.slider {
    position: relative;
}






// bs5 multi-slider css code
@include media-breakpoint-down(md) {
    .carousel-inner .carousel-item > div {
        display: none;
    }
    .carousel-inner .carousel-item > div:first-child {
        display: block;
    }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
    gap: 20px;
}

@include media-breakpoint-up(md) {
    
    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(calc(50% + 25px));
    }
    
    .carousel-inner .carousel-item-start.active, 
    .carousel-inner .carousel-item-prev {
      transform: translateX(calc(-50% - 25px));
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start { 
  transform: translateX(0);
}




// bs5 slider code to customize next and previous buttons

.carousel-control-prev, .carousel-control-next {
  font-family: "FontAwesome";
  position: absolute;
  z-index: 999;
  bottom: -60px;
  width: 30px;
  height: 30px;
  transition: ease-in-out .2s all;
  opacity: .9;
  top: inherit;

  align-items: center;
  text-rendering: auto;
  &:focus {
    border: none;
    outline: none;
  }
  i {
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #9dafbd;
  }
}

.carousel-control-next {
  left: 35px;
  i {
    color: $primary;
  }
  &:hover {
    i {
      color: $secondary;
    }
  }
}
.carousel-control-prev {
  left: 0px;
  i {
    color: $primary;
  }
  &:hover {
    i {
      color: $secondary;
    }
  }
}

}
.pageblock_5779 {
.tab-nav {
	position: relative;
	display: flex;
	justify-content: space-between;
	@include media-breakpoint-down(lg) {
		flex-direction: row;
		height: 100%;
		padding-right: 5em;
		padding-bottom: 10em;
		padding-left: 5em !important;
		border-top: 5px solid $primary;
		a {
			width: 10px;
			transform: rotate(69deg);
			transform-origin: left;
			white-space: nowrap;
			&:before {
				top: 0;
				left: -17.6px;
				transform: rotate(66deg);
			}
		}
		&:before {
			top: -26px;
			left: 0;
			width: 4px;
			height: 50px;
		}
		&:after {
			top: -26px;
			right: 0;
			width: 4px;
			height: 50px;
		}
	}
	@include media-breakpoint-up(lg) {
		flex-direction: column;
		height: 380px;
		padding: 3em 0;
		border-left: 5px solid $primary;
		a:before {
			top: 6px;
			left: -7.60px;
			transform: rotate(45deg);
		}
		&:before {
			top: 0;
			left: -26px;
			width: 50px;
			height: 4px;
		}
		&:after {
			bottom: 0;
			left: -26px;
			width: 50px;
			height: 4px;
		}
	}
	&:before {
		position: absolute;
		content: "";
		background-color: $primary;
	}
	&:after {
		position: absolute;
		content: "";
		background-color: $primary;
	}
	a {
		font-weight: bold;
		position: relative;
        font-size: 1.15em;
		padding-left: 1.5em;
		text-decoration: underline;
		color: rgba($primary, .6);
        &:not(.disabled):hover {
            cursor: pointer;
        }
        &.active{
            font-size: 1.25em;
            transition: .2s ease-in-out;
        }
        &:not(.disabled):hover, &:not(.disabled).active {
            color: $secondary;
			&:before {
				outline: 2px solid $white;
			}
        }
        &:before {
            position: absolute;
			width: 11px;
			height: 11px;
			content: "";
			background-color: $secondary;
        }
        &.disabled {
            cursor: not-allowed;
        }
	}
}


.tabs {
	.tab-container {
		@include media-breakpoint-up(lg) {
			display: grid;

			gap: 0 0;
			grid-template-columns: 130px 130px 1fr;
			grid-template-rows: 70px 1fr 70px;
			.content {
				padding-left: 2em;
			}
		}

		.image {
			position: relative;

			grid-area: 2 / 1 / 3 / 3;
			.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 75px;
				height: 75px;
				transform: translate(-50%, -50%);
			}
		}
		.content {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;

			grid-area: 2 / 3 / 3 / 4;

			h6 {
				font-size: .8em;
				font-family: "Montserrat", Arial, sans-serif;
				color: $secondary;
			}
		}
		.background {
			position: relative;
			background-color: $secondary;

			grid-area: 1 / 2 / 4 / 4;
			&:after {
				position: absolute;
				top: 0;
				left: 100%;
				width: 1000px;
				height: 100%;
				content: "";
				background-color: $secondary;
			}
		}
	}
}

}
.pageblock_5815 {
.container-md-big {
  @include media-breakpoint-down(sm) {
    max-width: 540px;
    padding-right: calc(var(--bs-gutter-x, 0.75rem)*2);
    padding-left: calc(var(--bs-gutter-x, 0.75rem)*2);
  }
  @include media-breakpoint-up(xxl) {
    max-width: 1375px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.navigation-main {
  position: absolute;
  z-index: 99;
  top: 0;
  transition: all .4s ease-in-out;
  width: 100%;
  /*background-color: $primary;*/
  &.navigation-open .header-content {
    transform: translateX(0%);
    opacity: 1;
  }
  &.fixed {
    position: fixed;

    background-color: $primary;
    .header {
      padding: .75em 0;
      .logo img {
        max-height: 4em;
      }
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    padding: 1.25em 0;
    transition: all .5s ease;
    justify-content: space-between;

    align-items: center;
    .logo {
      position: relative;
      z-index: 99;
      img {
        position: absolute;
        top: -33px;
        max-height: 12em;
        transition: all .5s ease;
      }
       @include media-breakpoint-down(sm) {
        img {
            max-height:5em;
        }
      }
      
      @media screen and ( max-height: 975px ){
        img {
            max-height:5em;
        }
      }
      
      
      
    }
  }
  .header-content {
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    transition: cubic-bezier(.81,.12,.65,.86) .6s;
    transform: translateX(100%);
    opacity: 0;
    background-color: $primary;
    @include media-breakpoint-down(md) {
      .header-content-menu {
        width: 60%;
      }
    }
    @include media-breakpoint-up(md) {
      .header-content-image {
        width: 35%;
        height: 100%;
    
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        -webkit-box-shadow: inset -3px 0 11px -2px rgba(0,0,0,.82);
        box-shadow: inset -3px 0 11px -2px rgba(0,0,0,.82);
      }
      .header-content-menu {
        width: 65%;
        padding-left: 10em;
        .triangle {
          position: absolute;
          right: 100%;
          transition: top .1s ease-in-out;
          border-width: 25px 35px 25px 0;
          border-style: solid;
          border-color: transparent $primary transparent transparent;
        }
      }
    }

    .header-content-menu {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0 auto;
      justify-content: center;
      .logo img {
        position: absolute;
        top: 2em;
        height: 100px;
      }

      ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style-type: none;
        li a {
          font-family: "Gotham";
          font-size: 2em;
          font-weight: 700;
          display: flex;
          padding: .3em .5em;
          text-decoration: none;
          color: $white;
          &:hover, &.active {
            color: $secondary;
          }
          
          @include media-breakpoint-down(md) {
            font-size: 1.125em !important;
            padding: 0.25em 0.5em;
          }
        }
      }
    }
  }
}


.burger-menu {
  font-size: 1.3em;
  font-weight: bold;
  z-index: 100;
  top: .7em;
  // right: 23px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  margin: 0 0 0 10%;
  padding: .8em .6em;
  cursor: pointer;
  -webkit-transition: top 1.5s ease-in-out;
  -moz-transition: top 1.5s ease-in-out;
  -o-transition: top 1.5s ease-in-out;
  transition: top 1.5s ease-in-out;
  transform: scale(.9);
  transform-origin: top right;
  text-transform: uppercase;
  color: $white;
  @include media-breakpoint-down(md) {
    right: 0;
  }
  @include media-breakpoint-up(lg) {
    right: 23px;
  }

  align-items: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
 
  
  .burger-buttons {
    margin-top: 0.3em;
    
    a {
        padding: 0.3em 0.9em !important;
        font-size: 1.3rem !important;
    }
  }
  
  .burger-text {
    font-family: "Gotham";
    // line-height: 2;
    overflow: hidden;
    height: 1.3em;

    .burger-text-open, .burger-text-closed {
      transition: .8s cubic-bezier(.68, -.6, .32, 1.6), .8s transform;
      transform: translateY(0);
    }
  }
  .burger-layers {
    display: flex;
    flex-direction: column;
    width: 30px;
    margin-left: .5em;
    padding: 0;
    cursor: pointer;

    align-items: flex-end;
    span {
      height: 3px;
      margin: 3px 0;
      transition: .4s cubic-bezier(.68, -.6, .32, 1.6), .2s background-color;
      background: $white;
      &:nth-of-type(1) {
        width: 100%;
      }
      &:nth-of-type(2) {
        width: 100%;
      }
      &:nth-of-type(3) {
        width: 100%;
      }
    }
  }
}
.navigation-main-open .burger-menu {
  .burger-buttons {
      display:none !important;
  }
  
  .burger-text {
    .burger-text-open, .burger-text-closed {
      transform: translateY(-100%);
    }
  }
  .burger-layers span {
    margin-left: -2px;
    background: $white;
    &:nth-of-type(1) {
      width: 50%;
      transform: rotatez(45deg) translate(-7px,12px);
      transform-origin: bottom;
    }
    &:nth-of-type(2) {
      width: 100%;
      transform: rotatez(-45deg);
      transform-origin: top;
    }
    &:nth-of-type(3) {
      width: 50%;
      transform: translate(-2px,-5px) rotatez(45deg);
      transform-origin: bottom;
    }
  }
}



}
.pageblock_5783 {
.project-header {

  position: relative;
  overflow: hidden;
  height: 80vh;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .cta-sticker {
    padding: 10px 30px;
    border-radius: 3px;
    margin-top: 30px;
    font-size: 20px;
    display: inline-block;
  }

  .slider-nav {
    .carousel-next,
    .carousel-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      i {
        background-color: transparent;
        color: rgba(white, .5);
        transition: all ease-in-out 0.2s;
        &:hover {
          color: rgba(white, 1);
        }
      }
    }
    .carousel-prev {
      left: 20px;
    }
    .carousel-next {
      right: 20px;
    }
  }
  .cta-link {
    position: absolute;
    bottom: 0;
    left: 15%;

    a {
      text-decoration: none;
    }

    .cta-link-downloads,
    .cta-link-images,
    .cta-link-prijslijst {
      padding: 0.5em 1em;
      transition: all 0.2s ease-in-out;
      border-top: 2px solid white;
      border-right: 2px solid white;
      border-left: 2px solid white;
      background-color: rgba(white, .8);

      &:hover {
        transform: translateY(10%);
        border-top: 2px solid $primary;
        border-right: 2px solid $primary;
        border-left: 2px solid $primary;
      }
      p::before {
        font-family: 'Font Awesome\ 5 Pro';
        display: inline-block;
        margin-right: 7.5px;
      }
    }
    .cta-link-images {
      p::before {
        content: ("\f302");
      }
    }
    .cta-link-downloads {
      p::before {
        content: ("\f019");
      }
    }
    .cta-link-prijslijst {
      p::before {
        content: ("\f0cb");
      }
    }
  }
}
}
.pageblock_5785 {
.building-type-badge {
  width: 75px;
  height: 75px;
}
}
.pageblock_5786 {
.media-item:nth-of-type(1n+5) {
   display:none;
}

.media-item .overlay {
    display: none;
    background-color: #000;
    opacity: 0.8;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    color: #fff;
}

.media-item:nth-child(4) .overlay {
    display: flex !important;
}




.media-container .media-wrapper {
	position: relative;
	display: inline-block;
	overflow: hidden;
	width: 100%;

	&:hover {
		a {
			.scaled-img {
				cursor: zoom-in;
				transform: scale(1.1);
			}
			&::after {
				opacity: 1;
			}
		}
	}
	a {
		position: relative;
		width: 100%;
		height: 200px; 
		display: block; 
		overflow: hidden;


		.scaled-img {
			position: relative;
			height: 100%;
			background-position: center;
			background-repeat: no-repeat; 
			background-size: cover;
			transition: all .15s ease-in-out;
		}

		&.large-img {
			height: 430px;
		}
	}
}



}
.pageblock_5787 {
.download-content{
    border: 2px solid $light;
    padding:10px;
    background-color: $light;
    color:$dark;
    text-decoration:none;
}

.download-content:hover {
    border: 2px solid $secondary;
    color:$dark;
}


}
